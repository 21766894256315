import React from 'react';
import Reviews from '../../Containers/Reviews';

import TestimonialsReviewerTwo from '../../Assets/testimonials_images/pic-24.png';
import TestimonialsReviewerThree from '../../Assets/testimonials_images/pic-03.png';
import TestimonialsReviewerFour from '../../Assets/testimonials_images/4.png';
import TestimonialsReviewerFive from '../../Assets/testimonials_images/pic-14.png';
import TestimonialsReviewerSix from '../../Assets/testimonials_images/pic-04.png';
import TestimonialsReviewerSeven from '../../Assets/testimonials_images/pic-16.png';



const testimonialsItems = [
    {
      text: `Very pleased with our basement finish. Tanisha was our PM. She was approachable, highly responsive and took care of all requests. Whole project was finished before expected date of completion. All staff very punctual, hard working and perfectionist in their own field. We get plan every Friday for all expected work in upcoming week.`,
      name: 'Sadia Ahmad',
      photo: TestimonialsReviewerTwo,
    },
    {
      text: `We are more than satisfied with our project. The project exceeded what we imagined it would be. One of the biggest things that stood out was the quality of the work and tradesmen. We felt that everyone who was working on the project had integrity. We would definitely recommend!`,
      name: 'Zaheer Abbas',
      photo: TestimonialsReviewerFive,
    },
    {
      text: `We hired AGM to finish our basement. The quality of work was excellent, the project was completed on time, and any issues that came up during the project were quickly handled by the Project Manager. I would definitely hire them again for any future Renovations.`,
      name: 'Jason Solilo',
      photo: TestimonialsReviewerThree,
    },
    {
      text: `We are really happy with our new basement!!
      AGM’s trades people we very friendly, professional and respectful of our space. Although there were a few hick-ups and stresses (as all construction jobs have), in the 5 weeks it took to complete, the team was able to overcome them and give us everything we wanted. We can’t wait to use our new space!`,
      name: 'Kevin Ribeiro',
      photo: TestimonialsReviewerSix,
    },
    {
      text: `I had my Bathrooms re-done by AGM.  Expanded the main floor powder room from a two piece to a three adding a shower. Had the Main bathroom redone, and complete gutted the Master suite to add a walk-in closet, freestanding tub, and walk-in shower. There were issues along the way, Shua the designer and Anton the Project Manager kept me informed and the projects moving along. The tile work and finishes are just beautiful I very pleased with how it has turned out.`,
      name: 'Gary Bradley',
      photo: TestimonialsReviewerSeven,
    },
    {
      text: `It's been a couple of months now, but we are enjoying our new master ensuite after our renovations by AGM.  The room in our new shower & bathroom overall is so nice & it's much brighter too!  I loved having our own designer to help put everything together & I am so glad we got to see the samples in person because the catalog photos did not do the actual products any favors. Our contractor was very helpful through the whole process & made sure to communicate to the project manager if any issues came up.  They both talked us through & we were able to find solutions.`,
      name: 'Cobi Cloutier',
      photo: TestimonialsReviewerFour,
    }
];

export const ReviewsPage = (props) => {


  return (
    <>
        <Reviews
            loopItems={testimonialsItems}
            paragraph={''}
            title={'AGM Renovations Reviews | AGM Bathroom Renovations | AGM Renovations'}
            description={'Discover how AGM Renovations reviews their bathroom remodeling processes to ensure complete customer satisfaction. Learn more today.'}
            keywords={'AGM Renovations reviews'}
        >
          <p style={{ textAlign: "center" }}>
          AGM Renovations reviews each step of their bathroom remodeling process to ensure 100 percent customer satisfaction.
          <br/><br/>
           We encourage you to try our 3D Creator and get your new bathroom designed and built just for you from the comfort of your
            home.
            <br/><br/>
            AGM Renovations constantly reviews different tactics to ensure we offer the highest-quality bathroom
            renovation projects at the most affordable prices. AGM Renovations reviews the industry to see what we can
            do to earn our customers&#39; business. It’s why we implemented a price match guarantee.
            <br/><br/>
            We also offer a quoted price guarantee on all our bathroom renovations. This means there are no hidden fees
            and what you see is what you pay. AGM Renovations reviews of the industry also taught us that many
            prospective customers are worried about their renovation company sticking to the timeline they quote. We
            commit to paying $100.00 for every business day that AGM Renovations exceeds the proposed finish date.
            <br/><br/>
            Ready to see what we can make happen for your bathroom renovation project? 
            <br/>
            Get your free quote today!
          </p>
        </Reviews>
    </>
  )

}